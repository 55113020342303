import request from "../utils/request"
const baseURL = '/opt/'
// 登陆
export function login(params) {
  return request ({
    url: '/system/login/wechat',
    method: 'post',
    data: params
  })
}
//个人信息
export function reimburseBaseInfo() {
  return request ({
    url: `${baseURL}exp/reimburse/baseInfo`,
    method: 'post',
  })
}
//个人报销申请页面
// 通过二维码识别出的URL后的参数，获取到对应识别码，即可获取对应员工信息,目前没有code
export function employeeInductionInfo(params) {
  return request ({
    url: `${baseURL}bd/employee/inductionInfo`,
    method: 'post',
    data: params
  })
}
//入职信息表提交
export function employeeInductionSubmit(params) {
  return request ({
    url: `${baseURL}bd/employee/inductionSubmit`,
    method: 'post',
    data: params
  })
}

