<template>
  <div class="">
    <div class="payApplyTitle">
      <van-nav-bar
        fixed
        title="个人信息"
        left-arrow
        @click-left="onClickLeft"
      />
    </div>
    <div class="payMain">
      <!-- 发起人信息 -->
        <van-form validate-trigger="onSubmit" colon ref='form'>
            <div class="titlePerson">基础信息</div>
            <van-field 
                  label="姓名"
                  v-model="listPayUser.name"
                  name="name"
                /> 
            <van-field 
                  label="联系方式"
                  v-model="listPayUser.mobile" type="tel"
                  name="mobile" 
                /> 
            <van-field 
                  label="身份证号"
                  v-model="listPayUser.idNo" type="number" 
                  placeholder="请输入"
                  name="idNo" maxlength="18"
                  :required="true"
                  :rules="[
                    {
                    pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                    message: '证件号码格式有误！',
                    trigger: 'blur'
                  }
                  ]"
                />
            <van-field 
                  label="身高"
                  placeholder="请输入身高/cm" type="number"
                  v-model="listPayUser.height"  
                  name="height"
                />
            <van-field 
                  label="体重"
                  placeholder="请输入体重/kg" type="number"
                  v-model="listPayUser.weight" 
                  name="weight"
                />

            <van-field name="gender" label="性别"
                  :required="true" 
                  >
              <template #input>
                <van-radio-group v-model="listPayUser.gender" direction="horizontal">
                  <van-radio name="1">男</van-radio>
                  <van-radio name="2">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field 
                  label="紧急联系人"
                  v-model="listPayUser.emergencyContact" 
                  placeholder="请输入"
                  name="emergencyContact"
                  :required="true" 
                />
            <van-field 
                  label="与本人关系"
                  v-model="listPayUser.emContactRelationship" 
                  placeholder="请输入"
                  name="emContactRelationship"
                  :required="true" 
                />
            <van-field 
                  label="联系方式"
                  v-model="listPayUser.emContactMobile" type="tel"
                  placeholder="请输入手机号"
                  name="emContactMobile"
                  :required="true" 
                />
            <van-field 
                  label="籍贯"
                  v-model="listPayUser.nativePlace"  
                  name="nativePlace"
                  :required="true" 
                  placeholder="请输入"
                />
            <van-field  
                  label="户籍所在地"
                  v-model="listPayUser.residenceAddress"  
                  placeholder="请输入"
                  name="residenceAddress"
                  :required="true" 
                />
            <van-field 
                  label="现住地址"
                  v-model="listPayUser.currentAddress"  
                  placeholder="请输入"
                  name="currentAddress"
                  :required="true" 
                />
            <van-field
                  label="血型" 
                  is-link readonly
                  v-model="listPayUser.bloodType"
                  name="bloodType"
                  placeholder="请选择"
                  @click="handlePicker(1)"
                />
               
            <van-field name="maritalStatus" label="婚姻状况"
                  :required="true">
              <template #input>
                <van-radio-group v-model="listPayUser.maritalStatus" direction="horizontal">
                  <van-radio name="0">未婚</van-radio>
                  <van-radio name="1">已婚</van-radio>
                </van-radio-group>
              </template>
            </van-field>            
            <van-field name="fertilityStatus" label="生育情况"
                  :required="true"
                  >
              <template #input>
                <van-radio-group v-model="listPayUser.fertilityStatus" direction="horizontal">
                  <van-radio name="0">未育/未妊辰</van-radio>
                  <van-radio name="1">已育</van-radio>
                </van-radio-group>
              </template>
            </van-field>            
            <van-field
                  label="政治面貌" 
                  is-link readonly
                  v-model="listPayUser.politicalOutlook"
                  placeholder="请选择"
                  name="politicalOutlook"
                  @click="handlePicker(2)"
                  
                />
            <van-field 
                  label="体检证明附件"
                  name="physicalExamFiles" >
                    <template #input>
                      <van-uploader
                        v-model="fileFormatA" 
                        :after-read="afterReadA" @delete="deleteA"
                        :max-size="3072 * 1024" accept="image/*"
                        :max-count="5"
                        deletable
                        show-upload
                      >
                      </van-uploader>
                    </template>
            </van-field> 
            <div class="titlePerson titleEdu">教育信息</div>         
            <van-field
                  label="最高学历" 
                  is-link readonly
                  v-model="listPayUser.highestEducation"
                  placeholder="请选择"
                  name="highestEducation"
                  @click="handlePicker(3)"
                  :required="true"
                />
            <van-field 
                  label="所属院校"
                  placeholder="请输入"
                  v-model="listPayUser.highestEduCollege" 
                  name="highestEduCollege"
                  :required="true" 
                />            
            <van-field 
                  label="所学专业"
                  placeholder="请输入"
                  v-model="listPayUser.specializedSubject" 
                  name="specializedSubject"
                  :required="true" 
                />                        
            <van-field 
                  label="最高学历"
                  name="highestEduFiles" >
                    <template #input>
                      <van-uploader
                        v-model="fileFormatB" 
                        :after-read="afterReadB" @delete="deleteB"
                        :max-size="3072 * 1024" accept="image/*"
                        :max-count="1"
                        deletable
                        show-upload
                      >
                      </van-uploader>
                    </template>
            </van-field> 
            <div class="titlePerson titleEdu">银行卡信息</div>         
                <van-field 
                  label="银行卡号"
                  placeholder="请输入"
                  v-model="listPayUser.bankNo"  type="number"
                  name="bankNo"
                  :required="true" 
                />              
                <van-field 
                  label="所属银行"
                  placeholder="请输入"
                  v-model="listPayUser.bankName" 
                  name="bankName"
                  :required="true" 
                />                 
                <van-field 
                  label="公积金账号"
                  placeholder="请输入"
                  v-model="listPayUser.providentFund" 
                  name="providentFund"
                />     
        </van-form>     
        <div class="empty"></div>
        <!-- 保存 -->
        <div class="savePerson" @click="handleSubmit" native-type="submit">保存</div>
    </div>
    <div class="vantList">
        <!-- 选择器 -->
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from "moment";
import "../../styles/personInfo.css";
import OSS from 'ali-oss';
import ossConfig from '../../utils/ossConfig';
import { reimburseBaseInfo,employeeInductionSubmit,employeeInductionInfo} from '../../api/personInfo';
import { NavBar,Dialog ,RadioGroup,Radio , DatetimePicker, Popup, Form ,Field, Toast ,Uploader,Overlay , Button , Picker, Grid, GridItem} from 'vant';
Vue.use(NavBar).use(DatetimePicker ).use(Dialog ).use(Popup).use(RadioGroup ).use(Radio).use(Toast).use(Form ).use(Overlay ).use(Uploader).use(Field).use(Button ).use(Picker).use(Grid).use(GridItem);

export default {
  name: 'personInfo',
  data() { 
    return {
      showPicker:false, //弹出框
      listPayUser:{
        id:'',
        name:'',
        mobile:'',
        idNo:'',
        height:'',
        weight:'',
        gender:'',
        emergencyContact:'',
        emContactRelationship:'',
        emContactMobile:'',
        nativePlace:'',
        residenceAddress:'',
        currentAddress:'',
        bloodType:'',
        maritalStatus:null,
        fertilityStatus:null,
        politicalOutlook:'',
        highestEducation:'',
        highestEduCollege:'',
        specializedSubject:'',
        physicalExamFiles:[],
        highestEduFiles:[],
        bankNo:'',
        bankName:'',
        providentFund:'',
      },
      confirmValue:'',//弹出框点击确认时是哪个
      clientOSS:'',
      columns:[],
      fileFormatA:[],
      fileFormatB:[],
      columnsBlood:[ "A","B","AB","O","其他"],
      columnsLook:['群众', '中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士'],//政治面貌
      columnsEdu:[' 小学及以下', '初中', '高中', '大专', '本科', '研究生', '硕士', '博士及以上'],//最高学历
    };
  },
  created() {
  },
  mounted(){
    this.clientOSS = new OSS(ossConfig);
    this.getUserInfo(); //先获取个人信息
  },
  methods: {
    // 获取用户信息
    getUserInfo (){
      reimburseBaseInfo().then(res => {
        console.log(res)
        this.listPayUser.sponsorPhone = res.resultData.sponsorPhone
        this.listPayUser.sponsorName = res.resultData.sponsorName
        this.listPayUser.id = res.resultData.sponsorId
        this.getInductionInfo(); 
      })
    }, 
  //获取详细信息
    getInductionInfo(){
      const params = {
        loginPid:this.listPayUser.id ,
        showEduUrl:1,
        showExamUrl:1
      };
      employeeInductionInfo(params).then(res => {
        console.log(res)
        this.listPayUser = res.resultData
        this.listPayUser.maritalStatus = JSON.stringify(res.resultData.maritalStatus) 
        this.listPayUser.fertilityStatus = JSON.stringify(res.resultData.fertilityStatus) 
        this.fileFormatB.push({url:res.resultData.highestEduFiles[0].fileUrl})
        res.resultData.physicalExamFiles.forEach(item => {
          this.fileFormatA.push({url:item.fileUrl})
        })
      })
    },
      // 重新提交信息   
    handleSubmit(){
      if (!this.listPayUser.idNo  || !this.listPayUser.gender  || !this.listPayUser.emergencyContact  || !this.listPayUser.bankNo || !this.listPayUser.bankName
          || !this.listPayUser.emContactRelationship || !this.listPayUser.emContactMobile || !this.listPayUser.nativePlace || !this.listPayUser.residenceAddress || !this.listPayUser.currentAddress || !this.listPayUser.bloodType
          || !this.listPayUser.maritalStatus || !this.listPayUser.fertilityStatus || !this.listPayUser.highestEducation || !this.listPayUser.highestEduCollege || !this.listPayUser.specializedSubject || !this.listPayUser.providentFund
          ) {
        Toast('请先填写必选项');
        return
      }
        const params = this.listPayUser
        console.log(params)
        employeeInductionSubmit( params ).then(res => {
            this.$toast.success('保存成功')
            this.$router.push({
                name: 'home',
                query: {
                  status:1
                }
            })
          })
    },
    // 上传图片
    afterReadB(file) {
      // 此时可以自行将文件上传至服务器
        const fileN = file.content;
        const fileU = file.file.name;
        const base64 = fileN.split(',').pop();
        const fileType = fileN.split(';').shift().split(':').pop();
        const blob = this.toBlob(base64, fileType)
        this.readFile(blob).then(res => {
            let d1 = moment().format('YYYY/MM/DD')
            let d2 = moment().format('YYYYMMDDHHmmss')
            let mark = `erp/${d1}/reimInvoice_${d2}_${file.file.name}`;
            const buffer = new OSS.Buffer(res.target.result);
            this.clientOSS.put(mark, buffer).then(result => {
                this.imageUrl = result.url;
                console.log(this.imageUrl)
                this.listPayUser.highestEduFiles.push({
                      fileId:null,
                      fileName:fileU,
                      fileUrlMark:mark,
                      delFile:0
                    })
                               
                Toast('上传成功');
            }).catch(err => {
                Toast('上传失败');
            })
        })
      },
      afterReadA(file) {
      // 此时可以自行将文件上传至服务器
        const fileN = file.content;
        const fileU = file.file.name;
        const base64 = fileN.split(',').pop();
        const fileType = fileN.split(';').shift().split(':').pop();
        const blob = this.toBlob(base64, fileType)
        this.readFile(blob).then(res => {
            let d1 = moment().format('YYYY/MM/DD')
            let d2 = moment().format('YYYYMMDDHHmmss')
            let mark = `erp/${d1}/reimInvoice_${d2}_${file.file.name}`;
            const buffer = new OSS.Buffer(res.target.result);
            this.clientOSS.put(mark, buffer).then(result => {
                this.imageUrl = result.url;
                console.log(this.imageUrl)
                if(this.listPayUser.physicalExamFiles == null){
                  this.listPayUser.physicalExamFiles = []
                }
                this.listPayUser.physicalExamFiles = [...this.listPayUser.physicalExamFiles,
                    {
                      fileId:null,
                      fileName:fileU,
                      fileUrlMark:mark,
                      delFile:0
                    }]
                Toast('上传成功');
            }).catch(err => {
                Toast('上传失败');
                console.log(err)
            })
        })
      },
      deleteA(e){
        console.log(e)
        this.listPayUser.physicalExamFiles.forEach((item,index) =>{
          if(e.url && item.fileUrl == e.url){
            item.delFile = 1
          }
          if(e.url == undefined){
            if(e.file.name && item.fileName == e.file.name ){
              this.listPayUser.physicalExamFiles.splice(index,1)
            }
          }

        })
      },
      deleteB(e){
        console.log(e)
        this.listPayUser.highestEduFiles[0].delFile =  1
      },
      readFile(file) {
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(file);
              reader.onload = function(event) {
                  resolve(event)
              }
          })
      },
      toBlob(urlData, fileType) {
          let bytes = window.atob(urlData);
          let n = bytes.length;
          let u8arr = new Uint8Array(n);
          while (n--) {
              u8arr[n] = bytes.charCodeAt(n);
          }
          return new Blob([u8arr], { type: fileType });
      },
    // 单项选择器
    handlePicker(activeValue){
      this.columns = ''
      this.showPicker = true;
      if (activeValue == '1') {
        this.confirmValue = "bloodType"
        this.columns = this.columnsBlood
      } else if (activeValue == '2'){
        this.confirmValue = "politicalOutlook"
        this.columns = this.columnsLook
      } else if (activeValue == '3'){
        this.confirmValue = "highestEducation"
        this.columns = this.columnsEdu
      }
    },
    onConfirm(value) {
      // console.log(value)
      this.listPayUser[this.confirmValue] = value
      this.showPicker = false;
    }, 
    // 顶部返回按钮
    onClickLeft () {
      this.$router.push({
          name: 'home',
          query: {
            status:1
          }
      })
    },
    },
};
</script>
